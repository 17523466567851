import React from 'react'
import {
  Grid,
  Box,
  Typography,
  Stack,
  Button,
  ButtonProps,
  styled
} from '@mui/material'
import { Add as AddIcon, AccountCircle as UserIcon } from '@mui/icons-material'
import EmployeeNameInput from 'components/EmployerNameInput'
import StatusPill from 'components/StatusPill'

const UsersButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: '#3148D3',
  color: '#FFFFFF',
  padding: '11px 20px 11px 13px',
  '&:hover': {
    backgroundColor: '#050B0F'
  }
}))

interface IProps {
  epId: string
  userId: string
  code: string
  name: string
  status: string
  submitName: (name: string) => void
}

const SidePanelHeader = ({
  epId,
  userId,
  code,
  name,
  status,
  submitName
}: IProps) => {
  return (
    <Box sx={{ marginBottom: '40px' }}>
      <Box sx={{ padding: '10px 0', marginBottom: '25px' }}>
        <Typography
          sx={{ fontSize: '12px', fontWeight: 700, textTransform: 'uppercase' }}
        >
          {code}
        </Typography>
      </Box>
      <Grid
        container
        sx={{
          minHeight: '180px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <EmployeeNameInput
          submitName={submitName}
          currentName={name}
          readOnly={false}
          userId={userId}
        />
        <Grid
          item
          xs={4}
          display='flex'
          alignItems='baseline'
          justifyContent='right'
        >
          <UsersButton href={`/admin-portal/ep/${epId}`}>
            <Stack alignItems='center' direction='row' gap={1}>
              <UserIcon />
              Manage Users
            </Stack>
          </UsersButton>
        </Grid>
      </Grid>
      <StatusPill status={status} />
    </Box>
  )
}
export default SidePanelHeader
