import { useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'

interface Props {
  // eslint-disable-next-line react/require-default-props
  path?: string
}

const Redirect = ({ path }: Props) => {
  const { shutdown } = useIntercom()
  // shut intercom down and clear cookies
  if (process.env.REACT_APP_INTERCOM_APP_ID) {
    shutdown()
  }

  useEffect(() => {
    window.location.replace(
      path ??
        `${
          process.env.REACT_APP_IDENTITY_ENTRY ?? 'http://localhost:7106'
        }/?redirect_uri=${window.location.origin}`
    )
  }, [path])
  return null
}
export default Redirect
