import React, { createContext, useEffect, useMemo, useState } from 'react'
import { attemptSocketReconnect, phoenixSocket } from 'utils/phoenixSocket'

import Cookies from 'js-cookie'
import * as impersonationStorage from 'utils/impersonationStorage'
import { getAccessToken } from 'utils/authTokenProvider'

export type AuthenticationContextData = {
  isAuthenticated: boolean | undefined
  handleLogout: (message?: string) => void
  logout: () => void
  setIsAuthenticated: (isAuthenticated: boolean) => void
}

export const AuthenticationContext = createContext<AuthenticationContextData>({
  isAuthenticated: undefined,
  handleLogout: () => null,
  logout: () => null,
  setIsAuthenticated: () => null
})

export const AuthenticationProvider = ({ children }: any): JSX.Element => {
  const accessToken = getAccessToken()

  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(
    !!accessToken
  )
  const [reconnectCounter, setReconnectCounter] = useState<number>(0)
  const logout = () => {
    const disconnectSocket = (callback?: () => void) => {
      phoenixSocket.disconnect(() => {
        impersonationStorage.clear()
        if (callback) callback()
      })
    }

    if (impersonationStorage.isSet()) {
      disconnectSocket(() => {
        window.open('/login/impersonate/done', '_self')?.close()
      })
    } else {
      // setIsAuthenticated(false)
      disconnectSocket()
    }

    localStorage.clear()
    Cookies.remove('tokens')
    window.location.replace(
      `${
        process.env.REACT_APP_IDENTITY_ENTRY ?? 'http://localhost:7106'
      }/logout?redirect_uri=${window.location.origin}`
    )
  }

  useEffect(() => {
    if (isAuthenticated) {
      const currentCookie = Cookies.get('tokens')
      const checkCookie = () => {
        setTimeout(() => {
          if (currentCookie !== Cookies.get('tokens')) {
            if (Cookies.get('tokens') === undefined) {
              logout()
            }
          } else {
            checkCookie()
          }
        }, 5000)
      }
      checkCookie()
    }
  }, [isAuthenticated])

  useEffect(() => {
    phoenixSocket.onError(() => {
      attemptSocketReconnect(reconnectCounter)
      setReconnectCounter(reconnectCounter + 1)
      if (reconnectCounter > 2) {
        logout()
      }
    })
  })
  const store: AuthenticationContextData = useMemo(
    () => ({
      logout,
      isAuthenticated,
      handleLogout: logout,
      setIsAuthenticated
    }),
    [isAuthenticated]
  )

  return (
    <AuthenticationContext.Provider value={store}>
      {children}
    </AuthenticationContext.Provider>
  )
}
